import React from "react";

import { css } from "@emotion/core";
import styled from "@emotion/styled";

import resolveWebView from "../../../../utils/webViewRedirect";
import { IconPredefined } from "../../../shared/icons/Icon";
import { PredefinedIconType } from "../../../shared/icons/Icon/Predefined";
import { Link } from "../../../shared/LinkComponent";
import NewHeadline from "../../../shared/NewHeadline";

const Overlay = styled.div`
    position: absolute;
    z-index: -1;
    right: 10%;
    top: 5em;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-around;
    align-items: center;
    padding-bottom: 2em;
    padding-top: 2em;
    width: 800px;
    height: 294px;
    border-radius: 6px;
    transition: 0.3s transform linear;
    border: 2px solid ${({ theme }) => theme.colors.blue.toString()};
    background: ${({ theme }) => theme.colors.white.toString()};

    ${({ theme }) => theme.breakpoints.upTo.small.css`
        position: fixed;
        top: 4.5em;
        right: 1em;
        width:  335px;
        height: 320px;
        opacity: 0.9;
    `};
`;

const CartLink = styled(Link)`
    font-size: 1.3rem;
    text-decoration: underline;
`;

const OrText = styled.span`
    font-size: 1.3rem;
`;

const getStyle = (isVisible: boolean) => {
    if (!isVisible) {
        return css`
            overflow: hidden;
            visibility: hidden;
            transform: translateY(-100%);
        `;
    } else {
        return css`
            z-index: 10;
            visibility: visible;
            transform: translateY(0);
        `;
    }
};

interface iAddedToSavedProductsProps {
    isVisible: boolean;
}

const AddedToSavedProducts = ({
    isVisible = false,
}: iAddedToSavedProductsProps) => {
    return (
        <Overlay css={getStyle(isVisible)}>
            <IconPredefined
                color="red"
                size="54px"
                type={PredefinedIconType.check}
            />
            <NewHeadline color="blue">
                Artikel zur Merkliste hinzugefügt
            </NewHeadline>
            <CartLink to={resolveWebView("/technik/list")}>
                Zur Merkliste
            </CartLink>
            <OrText>oder</OrText>
            <CartLink to={resolveWebView("/technik/produkte")}>
                weiter einkaufen
            </CartLink>
        </Overlay>
    );
};

export default AddedToSavedProducts;
