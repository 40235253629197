import React from "react";

import DetailPage from "../../../components/pages/TechnicShop/components/DetailPage";
import Page from "../../../components/shared/Page";

const TechnicDetailPage = ({ params: { articleNumber } }: any) => {
    return (
        <Page>
            <DetailPage articleNumber={articleNumber} />
        </Page>
    );
};
export default TechnicDetailPage;
